import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";

function Peridontics() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        PERIODONTICS
      </Typography>
      <img
        src={"/assets/services/perio.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "30px",
            }}
          >
            <Typography fontWeight={"bold"}>What is Periodontics?</Typography>
            <Typography>
              Periodontics is the branch of dentistry that focuses on the health
              of your gums and jawbone, the tissues that support your teeth. The
              field of periodontics treats conditions that affect the tissues
              "around your teeth," such as bone loss, gum recession and
              periodontal (gum) decease.
            </Typography>
            <Typography>
              There are several reasons why someone would need to see a
              periodontist. Some people only need to see a periodontist
              temporarily. In other words, they may need to undergo treatment
              that's outside the scope of practice for a general dentist. For
              example, maybe you need a gum graft, bone graft or frenectomy. A
              periodontist can perform your surgical treatment, then refer you
              back to your general dentist when your recovery is complete.
            </Typography>
            <Typography>
              Other people may need to see a periodontist long term. This
              includes people who are prone to gum disease. Those who are
              genetically predisposed to gum disease usually need periodontal
              cleanings in addition to routine cleanings at their dentist's
              office. In these cases, your periodontist will work closely
              alongside your general dentist. You'll continue to receive care
              from both of them in order to maintain optimal oral health.{" "}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(6),
            }}
          >
            <Typography fontWeight={"bold"}>Types of Periodontal Surgeries.</Typography>
            <Typography fontWeight={"bold"}>
              A periodontist can perform a wide range of periodontal treatments,
              including periodontal maintenance, scaling and root planing, and
              several types of periodontal surgery.
            </Typography>
            <strong>
              <li>Oral prophylaxis [teeth cleaning]</li>
              <li>Treatment for bleeding gums</li>
              <li>Treatment for gum diseases</li>
              <li>Flap surgeries</li>
              <li>Frenectomy</li>
              <li>Gingivectomy</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Peridontics;
