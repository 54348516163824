import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

function Laser() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        LASER DENTISTRY
      </Typography>
      <img
        src={"/assets/services/Lasers-in-Periodontal-Procedures.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "10px" : "30px",
          marginTop: "20px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            marginTop: "20px",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "70px",
            }}
          >
            <Typography fontWeight={"bold"}>
              What is Laser Dentistry?
            </Typography>
            <Typography>
              A dental laser is much more precise than the standard tools
              typically used in non-laser dental treatments. We are proud to use
              dental lasers to provide a wide array of services, including:
            </Typography>
            <ul>
              <strong>
                <li>Cosmetic laser dentistry</li>
                <li>Smile makeovers</li>
                <li>Teeth whitening</li>
                <li>Gum bleaching</li>
                <li>Periodontal treatment for gum disease</li>
                <li>Aphthous ulcer</li>
              </strong>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>Benefits of Laser Dentistry</Typography>
            <ul>
              <strong>
                <li>Minimally invasive procedure</li>
                <li>Less pain and discomfort </li>
                <li>Minimal bleeding </li>
                <li>Less damage to surrounding tissue </li>
                <li>Reduced infection risk </li>
                <li>Faster healing </li>
                <li>More comfortable treatment </li>
                <li>No stress of drills and needles </li>
                <li>Accurate and Precise result</li>
                <li>Return to daily routine faster</li>
              </strong>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Laser;
