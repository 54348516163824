import React from "react";
import { Grid, useMediaQuery, useTheme } from '@mui/material'

function GoogleReview() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Grid style={{ margin: "30px" }}>
        <iframe

            style={{
            width: isSmallScreen? "100%" : "150vh",

            height:"450px", // Adjusted height based on screen size
          }}
          src={"https://d68d676658584d9ebb5aa43fd2a225fa.elf.site"}

        ></iframe>
      </Grid>
    </div>
  );
}

export default GoogleReview;
