import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

function Digital() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 960px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        DIGITAL DENTISTRY
      </Typography>
      <img
        src={"/assets/services/digital.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            marginTop: "20px",
            padding: "20px",
          }}
        >
          <Grid item xs={12} sm={6} style={{ textAlign: "justify" }}>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: isSmallScreen ? "15px" : "20px",
              }}
            >
              What is Digital Dentistry?
            </Typography>
            <Typography style={{ fontSize: isSmallScreen ? "10px" : "14px" }}>
              The digital revolution has radically changed the world of
              dentistry. It involves the use of dental technology on devices
              which use computer-based or digital components. It enables dental
              practices to further enhance patient care. Above all, digital
              dentistry eliminates manual steps of dental procedures. For this
              reason, we can provide an efficient and more automated treatment
              process.
            </Typography>
            <Grid item>
              <Typography fontWeight="bold">
                Types of dental technology used at Classic Dental & Orthodontic
                Center are:
              </Typography>
              <ul
                style={{
                  textAlign: "justify",
                  fontSize: isSmallScreen ? "10px" : "14px",
                }}
              >
                <li>
                  <strong>Digital radiography</strong> - Dental radiography,
                  also known as dental X-rays, is a diagnostic tool used by
                  dentists to evaluate your teeth, gums, and jawbone. It
                  involves taking images of your mouth using a special camera
                  that emits low levels of radiation. That emit up to 70 percent
                  less radiation than traditional X-rays and more environmental
                  friendly. It is a safe and effective way to diagnose dental
                  problems such as cavities, gum disease, and impacted teeth. It
                  also helps dentists to plan and monitor dental treatments like
                  orthodontic braces, dental implants and Root Canal Treatments.
                </li>
                <li>
                  <strong>3D intra-oral scanner (iTero)</strong>
                </li>
                <li>
                  <strong>Intra-oral camera</strong> - allows seeing clear,
                  accurate images of the mouth. Shaped like a wand, the camera
                  can magnify images on a computer screen in real time. This
                  enables patients to clearly see what the dentist is doing or
                  referring to. In addition, the Intra-oral cameras can provide
                  a detailed view of hard to see areas in the mouth. This helps
                  to accurately identify problems such as a fractured tooth.
                </li>
                <li>
                  <strong>Dental photography</strong> - During a dental
                  photography session, the dentist will use a specialized camera
                  and lighting to capture images of the patient's teeth and gums
                  from various angles. These images are then analyzed to
                  identify any areas of concern, such as cavities, gum disease,
                  or tooth decay. Dental photography can be especially helpful
                  for patients who may have difficulty understanding their oral
                  health issues. By showing patients detailed images of their
                  teeth and gums, dentists can help them understand their
                  condition better and make more informed decisions about their
                  treatment.
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>
              Benefits of Digital Dentistry
            </Typography>
            <ul>
              <strong>
                <li>Accurate condition diagnosis</li>
                <li>Reduce time and costs </li>
                <li>Enhanced patient experiences </li>
                <li>Improved communication</li>
              </strong>
            </ul>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Digital;
