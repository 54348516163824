import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";

function Restorative() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        RESTORATIVE DENTISTRY
      </Typography>
      <img
        src={"/assets/services/Gum-Grafting-Market.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "70px",
            }}
          >
            <Typography fontWeight={"bold"}>
              What is Restorative Dentistry?
            </Typography>
            <Typography>
              Restorative dentistry involves the diagnosis, prevention &
              treatment or oral diseases. Restorative can replace missing teeth
              or repair missing part of the teeth structure. Tooth structure can
              be missing due to decay, deterioration of a previously placed
              restoration or fracture of the teeth.
            </Typography>
            <Typography>
              A dental restoration or dental filling will restore the function,
              integrity, & morphology of missing tooth structure resulting from
              caries or external trauma. These days thanks to a variety of
              options for dental restoration, you have many choices for
              repairing, worn, decayed, damaged or missing teeth & restoring
              healthy and beautiful smile. If you want to enhance your smile,
              you should always seek help from CLASSIC DENTAL as we go into the
              heart of it along with the science.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(4) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>Services we provide:</Typography>
            <strong>
              <li>Composite Filings</li>
              <li>GIC Fillings</li>
              <li>Post & Core</li>
              <li>Inlay & Onlays</li>
              <li>Veneers</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Restorative;
