import React, { useState, useCallback, useEffect } from "react";
import ImageViewer from "react-simple-image-viewer";
import { Typography, Grid } from "@mui/material";
import {  Hourglass } from "react-loader-spinner";
import galleryData from "./galleryData.json";

function Gallery() {
  const srcArray = galleryData.map((item) => item.src);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  const handleImageLoad = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true); 
  }, [galleryData]);

  return (
    <div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
          }}
        >
          <Hourglass
            visible={true}
            height="80"
            width="80"
            ariaLabel="hourglass-loading"
            wrapperStyle={{}}
            wrapperClass=""
            colors={["#97905E", "#000000"]}
          />
        </div>
      )}
      <Grid
        container
        style={{
          marginTop: "30px",
          textAlign: "center",
          justifyContent: "center",
          alignContent: "center",
        }}
      >
        <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
          PHOTO GALLERY
        </Typography>
        <Grid>
          {galleryData.map((image, index) => (
            <img
              key={index}
              src={image.src}
              onClick={() => openImageViewer(index)}
              width={"400px"}
              height={"400px"}
              style={{ margin: "2px" }}
              alt=""
              onLoad={handleImageLoad}
            />
          ))}
        </Grid>
        {isViewerOpen && (
          <ImageViewer
            src={srcArray}
            currentIndex={currentImage}
            disableScroll={false}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </Grid>
    </div>
  );
}

export default Gallery;
