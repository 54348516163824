import { Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import whyData from "./whyData.json";
import { Link } from "react-router-dom";
function WhyCDC() {
  const { care, professional, treatments } = whyData;
  const isSmallScreen = useMediaQuery("(max-width: 960px)"); // Adjust the width as needed

  return (
    <div>
      <Grid style={{ marginTop: "50px", textAlign: "center" }}>
        <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
          Why Classic Dental Clinic?
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          style={{ marginTop: "30px" }}
        >
          <Grid
            style={{
              marginTop: "30px",
              display: "flex",
              flexDirection: isSmallScreen ? "column" : "row",
              alignItems: "center",
              textAlign: isSmallScreen ? "center" : "left",
            }}
          >
            <img
              src={care.image}
              alt={care.title}
              height={isSmallScreen ? 150 : 300}
              width={isSmallScreen ? 200 : 400}
              style={{
                marginBottom: isSmallScreen ? "15px" : "20px",
              }}
            />
            <div
              style={{
                textAlign: isSmallScreen ? "center" : "left",
                marginLeft: isSmallScreen ? "0" : "100px",
              }}
            >
              <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
                {care.title}
              </Typography>
              <Typography>{care.description}</Typography>
              <Typography
                component={Link}
                to="/about"
                style={{
                  color: "#97905E",
                  fontSize: "12px",
                  textDecoration: "underline",
                  fontWeight: "bold",
                  textDecorationColor: "#97905E",
                }}
              >
                LEARN MORE &gt;
              </Typography>
            </div>
          </Grid>

          {isSmallScreen ? (
            <React.Fragment>
              <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                <img
                  src={professional.image}
                  alt={professional.title}
                  height={isSmallScreen ? 150 : 300}
                  width={isSmallScreen ? 200 : 400}
                  style={{
                    marginLeft: isSmallScreen ? "0" : "15px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {professional.title}
                </Typography>
                <Typography>{professional.description}</Typography>
                <Typography
                  style={{
                    color: "#97905E",
                    fontSize: "12px",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    textDecorationColor: "#97905E",
                  }}
                  component={Link}
                  to="/about"
                >
                  LEARN MORE &gt;
                </Typography>
              </Grid>
            </React.Fragment>
          ) : (
            // Second Section for Larger Screens (Same as the original code)
            <React.Fragment>
              <Grid
                item
                xs={12}
                md={6}
                style={{
                  marginTop: "20px",
                  maxWidth: "70%",
                  alignItems: "center",
                  textAlign: isSmallScreen ? "center" : "right",
                }}
              >
                <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
                  {professional.title}
                </Typography>
                <Typography>{professional.description}</Typography>
                <Typography
                  style={{
                    color: "#97905E",
                    fontSize: "12px",
                    textDecoration: "underline",
                    fontWeight: "bold",
                    textDecorationColor: "#97905E",
                  }}
                  component={Link}
                  to="/about"
                >
                  LEARN MORE &gt;
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                <img
                  src={professional.image}
                  alt={professional.title}
                  height={isSmallScreen ? 150 : 300}
                  width={isSmallScreen ? 200 : 400}
                  style={{
                    marginLeft: isSmallScreen ? "0" : "0",
                  }}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
        {/* Section 3 */}
        <Grid
          style={{
            marginTop: "30px",
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            alignItems: "center",
            textAlign: isSmallScreen ? "center" : "left",
          }}
        >
          <img
            src={treatments.image}
            alt={treatments.title}
            height={isSmallScreen ? 150 : 300}
            width={isSmallScreen ? 200 : 400}
            style={{
              marginBottom: isSmallScreen ? "15px" : "20px",
            }}
          />
          <div
            style={{
              textAlign: "center",
              marginLeft: isSmallScreen ? "0" : "100px",
              textAlign: isSmallScreen ? "center" : "left",
            }}
          >
            <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
              {treatments.title}
            </Typography>
            <Typography>{treatments.description}</Typography>
            <Typography
              style={{
                color: "#97905E",
                fontSize: "12px",
                textDecoration: "underline",
                fontWeight: "bold",
                textDecorationColor: "#97905E",
              }}
              component={Link}
              to="/services"
            >
              LEARN MORE &gt;
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default WhyCDC;
