import { Container } from "@mui/material";
import React from "react";
import Welcome from "./Welcome";
import WhyCDC from "./WhyCDC";
import Services from "./Services.jsx";
import Panelum from "./Panelum.js";
import Video from "./Video.jsx";
import GoogleReview from "./GoogleReview.jsx";

function HomePage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Video />
      <Container>
        <Welcome />
        <WhyCDC />
        <Services />
      </Container>
      <Panelum />
      <GoogleReview />
    </div>
  );
}

export default HomePage;
