import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import { SocialIcon } from "react-social-icons";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

function ContactDetails() {
  const phoneNumber = "8073831873";
  const emailAddress = "classicorthodonticcentre@gmail.com";

  const handleEmailClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  const handleWhatsAppClick = () => {
    const whatsappURL = `https://wa.me/${phoneNumber}`;
    window.open(whatsappURL, "_blank");
  };
  return (
    <div style={{ textAlign: "start" }}>
      <Grid>
        <Typography style={{ fontSize: "18px", fontWeight: "bold" }}>
          Drop us a line!
        </Typography>
        <Typography style={{ marginTop: "10px" }}>
          Need a consultation? Send us a message or call us, and we'll schedule
          your appointment!
        </Typography>
        <Button
          onClick={handleWhatsAppClick}
          style={{
            marginTop: "20px",
            color: "white",
            backgroundColor: "#425a6e",
          }}
        >
          <SocialIcon
            style={{ width: "40px", marginRight: "20px", height: "40px" }}
            url="www.whatsapp.com"
          />
          Message us on WhatsApp
        </Button>
      </Grid>
      <Grid style={{ marginTop: "20px" }}>
        <Typography style={{ fontSize: "20px", fontWeight: "bold" }}>
          Classic Dental clinic & Orthodontic Center
        </Typography>
        <Typography>
          Classic Dental clinic & Orthodontic Center,
          <br />
          #544/30,1st Cross Road,Anganeya Badavane,Kuvempu Nagar,
          <br />
          Davanagere,Karnataka,India
        </Typography>
        <Grid
          style={{ marginTop: "30px", display: "flex", flexDirection: "row" }}
        >
          <CallIcon onClick={handlePhoneClick} style={{ cursor: "pointer" }} />
          <Typography> Phone: {phoneNumber}</Typography>
        </Grid>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <EmailIcon onClick={handleEmailClick} style={{ cursor: "pointer" }} />
          <Typography> Email: {emailAddress}</Typography>
        </Grid>

        <Typography
          style={{ fontSize: "24px", fontWeight: "bold", marginTop: "20px" }}
        >
          Working Hours
        </Typography>
        <Typography>Open today 10:00am - 09:00pm</Typography>
        <Typography style={{ marginTop: "20px" }}>
          Every Month Last Sunday Clinic Will Be Closed
        </Typography>
      </Grid>
    </div>
  );
}
export default ContactDetails;
