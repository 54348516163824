import React from "react";
import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

function BiService({ leftService, rightService }) {
  const isSmallScreen = useMediaQuery("(max-width: 960px)");

  return (
    <Container>
      <Grid
        style={{
          display: "flex",
          flexDirection: isSmallScreen ? "column" : "row",
        }}
      >
        <Grid
          style={{
            textAlign: "center",
            justifyContent: "center",
            marginBottom: isSmallScreen ? "15px" : "30px",
            marginRight: isSmallScreen ? "0" : "15px",
          }}
        >
          <img
            src={leftService.src}
            alt={leftService.title}
            height={isSmallScreen ? 150 : 400}
            width={isSmallScreen ? 200 : 600}
          />
          <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
            {leftService.title}
          </Typography>
          <Typography>{leftService.desciption}</Typography>
          <Typography
            component={Link}
            to={leftService.link}
            style={{
              color: "#97905E",
              fontSize: isSmallScreen? '10px' : "12px",
              textDecoration: "underline",
              fontWeight: "bold",
              textDecorationColor: "#97905E",
            }}
          >
            LEARN MORE &gt;
          </Typography>
        </Grid>

        <Grid
          style={{
            textAlign: "center",
            justifyContent: "center",
            marginBottom: isSmallScreen ? "15px" : "0",
          }}
        >
          <img
            src={rightService.src}
            alt={rightService.title}
            height={isSmallScreen ? 150 : 400}
            width={isSmallScreen ? 200 : 600}
            style={{
              objectFit: "cover",
            }}
          />
          <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
            {rightService.title}
          </Typography>
          <Typography>{rightService.desciption}</Typography>
          <Typography
            component={Link}
            to={rightService.link}
            style={{
              color: "#97905E",
              fontSize: isSmallScreen? '10px' : "12px",
              textDecoration: "underline",
              fontWeight: "bold",
              textDecorationColor: "#97905E",
            }}
          >
            LEARN MORE &gt;
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}

export default BiService;
