import { Grid, Typography } from "@mui/material";
import React from "react";
import MonoService from "./MonoService";
import BiService from "./BiService";
import servicesData from "./servicesData.json";
function Services() {
  const {
    orthodontic,
    invisalign,
    oral,
    restorative,
    endodontics,
    prosthodontics,
    perodontics,
    pediatric,
    implants,
    cosmetic,
    laser,
    digital,
  } = servicesData;

  return (
    <div>
      <Grid style={{ marginTop: "20px", textAlign: "center" }}>
        <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
          Our Services
        </Typography>
        <MonoService service={orthodontic} />
        <BiService leftService={invisalign} rightService={oral} />
        <MonoService service={restorative} />
        <BiService leftService={endodontics} rightService={prosthodontics} />
        <MonoService service={perodontics} />
        <BiService leftService={pediatric} rightService={implants} />
        <MonoService service={cosmetic} />
        <BiService leftService={laser} rightService={digital} />
      </Grid>
    </div>
  );
}

export default Services;
