import React from "react";
import {
  Avatar,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import digitalData from "./digitalData.json";
function Digital() {
  const { digital } = digitalData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div style={{ marginTop: "50px" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item xs={12}></Grid>
        {digital.map((member) => (
          <Grid
            item
            key={member.id}
            xs={12}
            md={4}
            lg={3}
            style={{ textAlign: "center", margin: "20px" }}
          >
            <Typography
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "20px",
              }}
            >
              {member?.title}
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: isSmallScreen ? "60px" : "30px",
                borderRadius: "50%",
                width: "200px",
                height: "200px",
                overflow: "hidden",
              }}
            >
              <Avatar
                sx={{ width: "100%", height: "100%" }}
                alt={member.name}
                src={member.profileImage}
              />
            </div>
            <Typography>{member.name}</Typography>
            <Typography>{member.designation}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Digital;
// {
//   "profileImage": "assets/about/atp.jpg",
//   "name": "Santosh",
//   "title": "Digital Co-Ordinator"
// }
