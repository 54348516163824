import React from "react";
import { Avatar, Grid, Typography ,useMediaQuery,useTheme} from "@mui/material";
import auxiliaryData from "./auxiliary.json";
function Auxiliary() {
  const { auxiliary } = auxiliaryData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div style={{ marginTop: "50px" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Dental Auxiliary Team
          </Typography>
        </Grid>
        {auxiliary.map((member) => (
          <Grid
            item
            key={member.id}
            xs={12}
            md={4}
            lg={3}
            style={{ textAlign: "center" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: isSmallScreen ? '70px' : "30px",
                borderRadius: "50%",
                width: "200px",
                height: "200px",
                overflow: "hidden",
              }}
            >
              <Avatar
                sx={{ width: "100%", height: "100%" }}
                alt={member.name}
                src={member.profileImage}
              />
            </div>
            <Typography>{member.name}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Auxiliary;
