import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

function Implants() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontSize: "24px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        DENTAL IMPLANTS
      </Typography>
      <img
        src={"/assets/services/dental-implants.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "10px" : "30px",
          marginTop: "30px",
        }}
      >
        <Grid
          style={{
            marginTop: "10px",
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginRight: "20px",
              marginLeft: isSmallScreen ? "0" : "70px",
            }}
          >
            <Typography fontWeight={"bold"}>
              What is Dental Implants?
            </Typography>
            <Typography>
              Unlike earlier days where teeth loss was only treatable using
              dentures and bridges, today, missing teeth are treated using
              dental implants.
            </Typography>
            <Typography>
              A dental implant is a titanium screw (like a tooth root) that is
              surgically placed into the jaw bone beneath the gum line that
              allows the Implantologist to fix a crown on the top of that,
              thereby providing the appearance of a natural tooth.
            </Typography>
            <Typography>
              Dental implants are secured in the jaw bone and are not visible
              once surgically placed. They will secure crowns, bridges or
              dentures by a variety of means. Dental implants stabilize bone,
              preventing its loss, along with replacing lost teeth, implants
              helps in maintaining the jaw bones shape and density. Dental
              implants help you eat, chew, smile, talk and look completely
              natural. Dental implants have the highest success rate of any
              implanted surgical device.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>
              Dental Implants may be right for you, if you,
            </Typography>
            <strong>
              <li>Have one or more missing teeth</li>
              <li>Have a jaw bone that has reached full growth</li>
              <li>Have adequate bone to serve the implants </li>
              <li>
                Don't have health conditions that will affect bone healing
              </li>
              <li>Have healthy oral tissues</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Implants;
