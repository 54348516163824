import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Button,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { WidthFull } from "@mui/icons-material";
import { Link } from "react-router-dom";

const drawerButtonStyles = {
  color: "#97905E",
  fontSize: "18px",
  "&:hover": {
    color: "#97905E",
    backgroundColor: "transparent",
  },
};

const DrawerComponent = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openProducts, setOpenProducts] = useState(false);

  const handleProductsClick = () => {
    setOpenProducts(!openProducts);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/" sx={drawerButtonStyles}>
                  Home
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/about" sx={drawerButtonStyles}>
                  About Us
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>

          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/services" sx={drawerButtonStyles}>
                  Services
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/gallery" sx={drawerButtonStyles}>
                  Gallery
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Typography>
                <Button component={Link} to="/contact" sx={drawerButtonStyles}>
                  Contact Us
                </Button>
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </IconButton>
    </>
  );
};

export default DrawerComponent;
