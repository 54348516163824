import React from "react";
import { Avatar, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import sepcialistsData from "./specialists.json";
function Specialists() {
  const { sepcialists } = sepcialistsData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div style={{ marginTop: "50px" }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Meet Our Specialists!
          </Typography>
        </Grid>
        {sepcialists.map((doc) => (
          <Grid
            item
            key={doc.id}
            xs={12}
            md={4}
            lg={3}
            style={{ textAlign: "center", margin: "20px" }}
          >
            <div
              style={{
                marginLeft: isSmallScreen ? '50px' : '40px',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "50%",
                width: "200px",
                height: "200px",
                overflow: "hidden",
              }}
            >
              <Avatar
                sx={{ width: "100%", height: "100%" }}
                alt={doc.name}
                src={doc.profileImage}
              />
            </div>
            <Typography>{doc.name}</Typography>
            <Typography>{doc.designation}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Specialists;
