import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import directorData from "./director.json";

function Director() {
  const { director, doctor } = directorData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Grid container style={{ marginTop: "50px", textAlign: "center" }}>
        <Grid item xs={12}>
          <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
            About Classic Dental Clinic
          </Typography>
        </Grid>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="center"
          style={{ marginTop: "30px" }}
        >
          {/* First Section */}
          <Grid item xs={12} md={6}>
            <img
              style={{
                border: "1px solid black",
                width: isSmallScreen ? "300px" : "330px",
              }}
              src={director.profile}
              height={300}
              alt="Director Profile"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography style={{ fontSize: "15px", fontWeight: "bold" }}>
              {director.message}
            </Typography>
            <Typography
              style={{
                marginTop: "20px",
                fontSize: "16px",
                fontWeight: "bold",
              }}
            >
              {director.name}
            </Typography>
            <Typography style={{ fontSize: "14px" }}>
              {director.designation}
            </Typography>
          </Grid>

          {/* Second Section */}
          {isSmallScreen ? (
            <React.Fragment>
              <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                <img
                  src={doctor.profile}
                  height={300}
                  alt="Doctor Profile"
                  style={{
                    border: "1px solid black",
                    width: isSmallScreen ? "300px" : "300px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {doctor.message}
                </Typography>
                <Typography
                  style={{
                    marginTop: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {doctor.name}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #97905E",
                      display: "inline-block",
                      marginBottom: "4px",
                      marginRight: "7px",
                      width: "20px",
                    }}
                  ></span>
                  {doctor.designation}
                </Typography>
              </Grid>
            </React.Fragment>
          ) : (
            // Second Section for Larger Screens (Same as the original code)
            <React.Fragment>
              <Grid item xs={12} md={6} style={{ marginTop: "20px" }}>
                <Typography style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {doctor.message}
                </Typography>
                <Typography
                  style={{
                    marginTop: "20px",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {doctor.name}
                </Typography>
                <Typography style={{ fontSize: "14px" }}>
                  <span
                    style={{
                      borderBottom: "1px solid #97905E",
                      display: "inline-block",
                      marginBottom: "4px",
                      marginRight: "7px",
                      width: "20px",
                    }}
                  ></span>
                  {doctor.designation}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <img
                  src={doctor.profile}
                  height={300}
                  alt="Doctor Profile"
                  style={{
                    marginLeft: "20px",
                    border: "1px solid black",
                    width: isSmallScreen ? "300px" : "300px",
                  }}
                />
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Director;
