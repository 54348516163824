import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";

function Cosmetic() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 960px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontSize: "24px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        COSMETIC DENTISTRY
      </Typography>
      <img
        src={"/assets/services/ESTHETICC.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          marginBottom: "30px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "50px",
            }}
          >
            <Typography
              style={{
                fontSize: isSmallScreen ? "15px" : "20px",
                fontWeight: "bold",
              }}
            >
              What is Cosmetic Dentistry?
            </Typography>
            <Typography>
              Preventive care is the key to a healthy smile. Cosmetic dentistry
              is the best course of action to boost your self-confidence, to
              look younger, correct aesthetic flaws and prevent future damage of
              your teeth. But whether it's elective or for treatment, we'll make
              sure your smile is healthy and beautiful!
            </Typography>
            <Typography>
              Cosmetic dentistry focuses on improving the appearance and
              aesthetics of your smile.{" "}
              <strong>Classic Dental Clinic & Orthodontic Centre </strong>
              provides cosmetic dental procedures like teeth whitening, dental
              bonding and veneers, smile makeovers etc.
            </Typography>
          </Grid>
          <Grid
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>
              Cosmetic Dentistry might be an option if you have,
            </Typography>
            <strong>
              <li>Chipped or cracked teeth</li>
              <li>Small gaps or spaces between your teeth</li>
              <li>Tooth discoloration and staining </li>
              <li>Misshapen teeth or Misaligned teeth </li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Cosmetic;
