import {
  Avatar,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Box,
  Link,
} from "@mui/material";
import React from "react";
import { Email, Facebook, Instagram, } from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import EmailIcon from "@mui/icons-material/Email";
const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const footerStyle = {
    background: "#F3EC91",
    color: "black",
    padding: "20px", // Add padding for better spacing
  };
  const currentYear = new Date().getFullYear();
  return (
    <footer style={footerStyle}>
      <Grid
        container
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        spacing={2}
        style={{
          justifyContent: "center",
          marginTop: "30px",
          marginBottom: "10px",
        }}
      >
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography
            style={{
              marginBottom: "20px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            CLASSIC DENTAL CLINIC & ORTHODONTIC CENTRE
          </Typography>
          <Typography>
            #544/30, 1ST CROSS, ANJANEYA BADAVANE, DAVANAGERE 577004, KARNATAKA,
            INDIA
          </Typography>
          <Typography>+91 8073831873</Typography>
        </Grid>
        <Grid item xs={6} sm={2} md={2}>
          <Grid style={{ display: "flex", flexDirection: "row" }}>
            <Grid container>
              <Grid item>
                <Link
                  href="https://www.facebook.com/cdcorthocentre"
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  <Facebook />
                </Link>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Link
                  href="https://www.instagram.com/classicdentalclinic?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  <Instagram />
                </Link>
              </Grid>
            </Grid>

            <Grid item>
              <Link
                href="mailto:classicorthodonticcentre@gmail.com"
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                <Email />
              </Link>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginTop: "20px", justifyContent: isSmallScreen? 'center' : "space-between" }}
        >
          <Typography style={{ fontSize: "12px" }}>
            COPYRIGHT © {currentYear}. ALL RIGHTS RESERVED
          </Typography>
          <Typography variant="body2" color="black" align="center">
            {"Website Developed By "}
            <Link
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.bytecrafts.in/"
              color="inherit"
            >
              bytecrafts.in
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
