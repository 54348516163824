import React from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import DrawerComponent from "./Drawer";
import logo from "./English LogoSAN.png";

const buttonStyles = {
  color: "black",
  fontSize: "18px",
  "&:hover": {
    color: "#97905E",
    backgroundColor: "transparent",
  },
};

const contactButtonStyles = {
  ...buttonStyles,
  marginRight: "40px", // Add margin to the right for Contact Us button
};

function Navbar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);

 

  return (
    <AppBar position="static" sx={{ backgroundColor: "white" }}>
      <CssBaseline />
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
        <img src={logo} alt="Elite Logo" style={{ height: "50px" }} />
        <Typography
          style={{
            textAlign: isMobile? "center" : "left",
            fontSize: isMobile?  "18px":"24px",
            color: "black",
            marginLeft: '20px'
          }}
        >
          CLASSIC DENTAL CLINIC & ORTHODONTIC CENTRE
        </Typography>
        </div>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <div>
            <Button component={Link} to="/" color="inherit" sx={buttonStyles}>
              Home
            </Button>
            <Button
              component={Link}
              to="/about"
              color="inherit"
              sx={buttonStyles}
            >
              About Us
            </Button>

            {/* Other buttons */}
            <Button
              component={Link}
              to="/services"
              color="inherit"
              sx={buttonStyles}
            >
              Services
            </Button>
            <Button
              component={Link}
              to="/gallery"
              color="inherit"
              sx={buttonStyles}
            >
              Gallery
            </Button>
            <Button
              component={Link}
              to="/contact"
              color="inherit"
              sx={contactButtonStyles}
            >
              Contact Us
            </Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
