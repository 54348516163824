import { Avatar, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import teamData from "./teamData.json";

function Team() {
  const { team } = teamData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div style={{ marginTop: "50px" }}>
      <Grid container justifyContent="center" alignItems="center" spacing={4}>
        <Grid item xs={12}>
          <Typography
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Meet Our Team!
          </Typography>
        </Grid>
        {team.map((member) => (
          <Grid
            item
            key={member.id}
            xs={12}
            md={4}
            lg={3}
            style={{ textAlign: "center", margin: "20px" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: isSmallScreen? '60px' : "30px",
                borderRadius: "50%",
                width: "200px",
                height: "200px",
                overflow: "hidden",
                objectFit:'contain'
              }}
            >
              <Avatar
                sx={{
                  width: "100%",
                  height: "100%",

                }}
                alt={member.name}
                src={member.profileImage}
              />
            </div>
            <Typography>{member.name}</Typography>
            <Typography>{member.designation}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Team;
