import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

function Pediatric() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        PEDIATRIC DENTISTRY
      </Typography>
      <img
        src={"/assets/services/pedolaksh.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "50px",
            }}
          >
            <Typography fontWeight={"bold"}>
              What is Pediatric Dentistry?
            </Typography>
            <Typography>
              Like all dentists, Pediatric dentists focus on oral health and the
              prevention and treatment of diseases and tooth decay. They also
              educate parents and other dental professionals on how best to
              treat children. Also, Pediatric dentists are experts at explaining
              procedures to children and building trust with their young
              patients.
            </Typography>
            <Typography>
              Pediatric dentists are dedicated to the oral health of children
              from infancy through the teen years. Children begin to get their
              baby teeth during the first 6 months of life. By age 6 or 7 years,
              they start to lose their first set of teeth, which eventually are
              replaced by secondary, permanent teeth. Without proper dental
              care, children face possible oral decay and disease that can cause
              a lifetime of pain & complications. Early childhood dental caries
              an infectious disease is 5 times more common in children than
              asthma and 7 times more common than hay fever. About 1 of 5(20%)
              children aged 5 to 11 years have at least one untreated decayed
              tooth.
            </Typography>
            <Typography>
              Children are not just small adults. They are not always able to be
              patient and cooperative during a dental exam. Pediatric dentists
              know how to examine and treat children in ways that make them
              comfortable. In addition, pediatric dentists use specially
              designed equipment in offices that are arranged and decorated with
              children in mind. A pediatric dentist offers a wide range of
              treatment options, as well as expertise and training to care for
              your child's teeth, gums and mouth. When your pediatrician
              suggests that your child receive a dental exam, you can be assured
              that a pediatric dentist will provide the best possible care.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>Services we provide:</Typography>
            <strong>
              <li>Tooth extractions</li>
              <li>RCT for children</li>
              <li>Pit & fissure sealants</li>
              <li>Composite & GIC restorations</li>
              <li>Habit breaking appliances</li>
              <li>Dental Crowns</li>
              <li>Fluoride treatments</li>
              <li>Preventive dental care</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Pediatric;
