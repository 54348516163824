import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import MessageForm from "./MessageForm";
import ContactDetails from "./ContactDetails.jsx";
import Maps from "./maps.jsx";
function Contact() {
  return (
    <Container>
      <Typography
        style={{ fontSize: "24px", fontWeight: "bold", textAlign: "center" }}
      >
        Contact Us
      </Typography>

      {/* Use Grid container to control layout */}
      <Grid container marginTop={"10px"} spacing={2} justifyContent="center">
        <Grid item xs={12} md={6} lg={6}>
          <MessageForm />
        </Grid>

        <Grid item xs={12} md={6} lg={6}>
          <ContactDetails />
        </Grid>
      </Grid>

      {/* Maps */}
      <Grid>
        <Maps />
      </Grid>

      {/* <Grid>
        <PanoramaGallery />
      </Grid> */}
    </Container>
  );
}

export default Contact;
