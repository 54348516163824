import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

function Oral() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        ORAL & MAXILLOFACIAL
      </Typography>
      <img
        src={"/assets/services/exn.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "70px",
            }}
          >
            <Typography fontWeight={"bold"}>
              What is Oral & Maxillofacial?
            </Typography>
            <Typography>
              Oral and Maxillofacial Surgery is the speciality of dentistry
              which includes the diagnosis, surgical and adjustive treatment of
              diseases, injuries and defects involving both the dunctional and
              aesthetic aspects of the hard and soft tisues of the oral and
              maxillofacial region.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>Services we provide:</Typography>
            <strong>
              <li>Tooth extraction</li>
              <li>Removal of wisdom tooth</li>
              <li>Minor Oral surgeries</li>
              <li>Surgical removal of oral pathologies</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Oral;
