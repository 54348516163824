// dataScene.js
const Scenes = {
  outside: {
    key: "outside",
    title: "Entrance",
    image: "assets/pano/p1.jpeg",
    pitch: 4,
    yaw: -5,
    hfov: 120,
    hotSpot: {
      reception: {
        pitch: -10,
        yaw: 10,
        scene: "reception",
      },
    },
  },
  reception: {
    key: "reception",
    title: "Reception Hall",
    image: "assets/pano/p2.jpeg",
    pitch: -8,
    yaw: -0,
    hfov: 120,
    hotSpot: {
      nexScene: {
        pitch: -15,
        yaw: -5,
        scene: "inside",
      },
      exit: {
        pitch: -9,
        yaw: -109,
        scene: "outside",
      },
    },
  },
  inside: {
    key: "inside",
    title: "Corridor A",
    image: "assets/pano/p9.jpeg",
    pitch: -5,
    yaw: -20,
    hfov: 130,
    hotSpot: {
      clinic1: {
        pitch: -9,
        yaw: -80,
        scene: "clinic1",
      },
      clinic2: {
        pitch: -14,
        yaw: -25,
        scene: "clinic2",
      },
      waitingHall: {
        pitch: -14,
        yaw: 90,
        scene: "waitingHall",
      },
      managingDirector: {
        pitch: -14,
        yaw: 185,
        scene: "manegement",
      },
      exit: {
        pitch: -14,
        yaw: 145,
        scene: "reception",
      },
      corridorB: {
        pitch: -15,
        yaw: 0,
        scene: "insideB",
      },
    },
  },
  manegement: {
    key: "manegement",
    title: "Managing Director",
    image: "assets/pano/p6.jpeg",
    pitch: -5,
    yaw: 5,
    hfov: 100,
    hotSpot: {
      exit: {
        pitch: -10,
        yaw: 110,
        scene: "inside",
      },
    },
  },
  clinic1: {
    key: "clinic1",
    title: "Clinic 1",
    image: "assets/pano/p7.jpeg",
    pitch: -20,
    yaw: -10,
    hfov: 100,
    hotSpot: {
      exit: {
        pitch: -9,
        yaw: 140,
        scene: "inside",
      },
    },
  },
  clinic2: {
    key: "clinic2",
    title: "Clinic 2",
    image: "assets/pano/p11.jpeg",
    pitch: -15,
    yaw: -10,
    hfov: 100,
    hotSpot: {
      exit: {
        pitch: -10,
        yaw: -130,
        scene: "inside",
      },
      corridorB: {
        pitch: -9,
        yaw: 130,
        scene: "insideB",
      },
    },
  },

  waitingHall: {
    key: "waitingHall",
    title: "Waiting Hall",
    image: "assets/pano/p8.jpeg",
    pitch: -10,
    yaw: -10,
    hfov: 120,
    hotSpot: {
      exit: {
        pitch: -14,
        yaw: -195,
        scene: "inside",
      },
    },
  },
  insideB: {
    key: "insideB",
    title: "Corridor B",
    image: "assets/pano/p12.jpeg",
    pitch: -5,
    yaw: 40,
    hfov: 100,
    hotSpot: {
      pantry: {
        pitch: -9,
        yaw: -55,
        scene: "pantry",
      },
      clinic3: {
        pitch: -9,
        yaw: -160,
        scene: "clinic3",
      },
      clinic4: {
        pitch: -9,
        yaw: -22,
        scene: "clinic4",
      },
      store: {
        pitch: -9,
        yaw: 40,
        scene: "store",
      },
      labF: {
        pitch: -16,
        yaw: 7,
        scene: "labFront",
      },
      exit: {
        pitch: -15,
        yaw: 90,
        scene: "inside",
      },
    },
  },
  pantry: {
    key: "pantry",
    title: "Pantry",
    image: "assets/pano/p15.jpeg",
    pitch: -20,
    yaw: -80,
    hfov: 100,
    hotSpot: {
      exit: {
        pitch: -14,
        yaw: -195,
        scene: "insideB",
      },
    },
  },
  store: {
    key: "store",
    title: "Store",
    image: "assets/pano/p18.jpeg",
    pitch: -10,
    yaw: 40,
    hfov: 100,
    hotSpot: {
      exit: {
        pitch: -10,
        yaw: 125,
        scene: "insideB",
      },
    },
  },
  clinic3: {
    key: "clinic3",
    title: "Clinic 3",
    image: "assets/pano/p25.jpeg",
    pitch: -20,
    yaw: 70,
    hfov: 100,
    hotSpot: {
      exit: {
        pitch: -10,
        yaw: -30,
        scene: "inside",
      },
      
    },
  },
  clinic4: {
    key: "clinic4",
    title: "Clinic 4",
    image: "assets/pano/p17.jpeg",
    pitch: -20,
    yaw: -5,
    hfov: 100,
    hotSpot: {
      exit: {
        pitch: -9,
        yaw: -120,
        scene: "insideB",
      },
    },
  },
  labFront: {
    key: "labFront",
    title: "Corridor C",
    image: "assets/pano/p19.jpeg",
    pitch: -10,
    yaw: 180,
    hfov: 100,
    hotSpot: {
      lab: {
        pitch: -9,
        yaw: -80,
        scene: "lab",
      },
      exit: {
        pitch: -15,
        yaw: 170,
        scene: "insideB",
      },
    },
  },
  lab: {
    key: "lab",
    title: "Lab",
    image: "assets/pano/p20.jpeg",
    pitch: -5,
    yaw: -60,
    hfov: 100,
    hotSpot: {
      exit: {
        pitch: -9,
        yaw: 90,
        scene: "labFront",
      },
    },
  },
};
export default Scenes;
