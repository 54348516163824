import { Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
function Welcome() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        style={{ textAlign: "center", alignSelf: "center", maxWidth: "700px" }}
      >
        <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
          Welcome to Classic Dental Clinic & Orthodontic Centre
        </Typography>
        <Grid style={{ display: "flex", flexDirection: "row" }}>
          <Divider
            color="#97905E"
            orientation="vertical"
            flexItem
            style={{
              height: "90px",
              width: "3px",
              margin: "0 8px",
            }}
          />
          <Typography>
            Dr. A.T Prakash and his team would like to extend a warm welcome to
            you and your family. Our dental clinic is committed in "
            <strong>
              Putting patients first through the delivery of high quality Oral
              health care in a professional and caring manner.
            </strong>
            "
          </Typography>
        </Grid>
        <Typography
          component={Link}
          to="/contact"
          style={{
            color: "#97905E",
            fontSize: "12px",
            textDecoration: "underline",
            fontWeight: "bold",
            textDecorationColor: "#97905E",
          }}
        >
          BOOK APPOINTMENT &gt;
        </Typography>
      </Grid>
    </div>
  );
}

export default Welcome;
