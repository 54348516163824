import { Grid, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import React from "react";
import servicesData from "./serviceData.json";
function Services() {
  const navigate = useNavigate(); // Use useNavigate directly

  const handleServiceClick = (url) => {
    navigate(url);
  };
  const handleHover = (e) => {
    e.currentTarget.style.backgroundColor = "rgba(0, 0, 0, 0)";
    e.currentTarget.style.transform = "scale(1.1)";
  };

  const handleLeave = (e) => {
    const target = e.currentTarget;
    target.style.transition = "transform 0.3s, background-color 0.3s";
    target.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
    target.style.transform = "scale(1)";
  };
  
  return (
    <div>
      <Grid
        container
        style={{
          marginTop: "80px",
          textAlign: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          style={{
            fontFamily: " sans-serif",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          Our Services
        </Typography>
        <Grid container spacing={2}>
          {servicesData.images.map((image) => (
            <Grid
              item
              key={image.id}
              style={{
                height: "300px",
                width: "320px",
                margin: "30px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundImage: `url(${image.src})`,
                position: "relative",
              }}
              onClick={() => handleServiceClick(image.link)}
              onMouseOver={handleHover}
              onMouseOut={handleLeave}
            >
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  zIndex: 1,
                }}
                onMouseOver={handleHover}
                onMouseOut={handleLeave}
              ></div>

              <Typography
                variant="h6"
                style={{
                  color: "white",
                  textAlign: "center",
                  zIndex: 2,
                  fontFamily: " sans-serif",
                }}
              >
                {image.title}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default Services;
