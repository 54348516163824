import React, { useState } from "react";
import {
  Card,
  CardContent,
  TextField,
  Button,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import emailjs from "emailjs-com";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function MessageForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await emailjs.send(
        "service_7vrurfv",
        "template_vr15yog",
        formData,
        "gYxQusnAqp6RtYHFv"
      );

      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error:", error);
      alert("Error sending email. Please try again.");
    }
  };

  return (
    <Card
      elevation={4}
      style={{
        textAlign: "center",
        maxWidth: "80%",
        margin: "auto",
        padding: 20,
      }}
    >
      <CardContent>
        <Typography style={{ fontSize: "18px" }}>
          Write to us and we will get back to you!!
        </Typography>
        <ValidatorForm
          onSubmit={handleSubmit}
          onError={(err) => {
            console.log(err);
          }}
        >
          <Grid container spacing={2} direction="column" justify="flex-start">
            <Grid item xs={12}>
              <TextValidator
                label="Name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                fullWidth
                margin="normal"
                validators={["required"]}
                errorMessages={["This field is required"]}
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
                validators={["required", "isEmail"]}
                errorMessages={[
                  "This field is required",
                  "Email is not valid",
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="Phone"
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                fullWidth
                margin="normal"
                validators={["required", "isNumber", "matchRegexp:^\\d{10}$"]}
                errorMessages={[
                  "This field is required",
                  "Must be a number",
                  "Phone number must be 10 digits",
                ]}
              />
            </Grid>

            <Grid item xs={12}>
              <TextValidator
                label="How can we help you?"
                type="text"
                name="description"
                value={formData.description}
                onChange={handleChange}
                fullWidth
                multiline
                rows={4}
                margin="normal"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                style={{
                  marginTop: 20,
                  color: "#9ea184 ",
                  backgroundColor: "white",
                  width: "100%",
                  border: "1px solid #9ea184 ",
                }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </ValidatorForm>
      </CardContent>
    </Card>
  );
}

export default MessageForm;
