import { Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
function MonoService({ service }) {
  const isSmallScreen = useMediaQuery("(max-width: 960px)");

  return (
    <Container>
      <Grid
        style={{
          textAlign: "center",
          justifyContent: "center",
          marginBottom: '30px'
        }}
      >
        <img
          src={service.src}
          alt={service.title}
          height={isSmallScreen ? 150 : 600}
          width={isSmallScreen ? 200 : 900}
          style={{
            marginBottom: isSmallScreen ? "15px" : "0",
          }}
        />
        <Typography style={{ fontSize: "24px", fontWeight: "bold" }}>
          {service.title}
        </Typography>
        <Typography>{service.desciption}</Typography>
        <Typography
          component={Link}
          to={service.link}
          style={{
            color: "#97905E",
            fontSize: "12px",
            textDecoration: "underline",
            fontWeight: "bold",
            textDecorationColor: "#97905E",
          }}
        >
          LEARN MORE &gt;
        </Typography>
      </Grid>
    </Container>
  );
}

export default MonoService;
