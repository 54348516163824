import { Route, Routes } from "react-router-dom";
import Navbar from "./NavBar/NavBar";
import HomePage from "./views/HomePage/HomePage";
import Footer from "./views/Footer";
import AboutUs from "./views/AboutUs/AboutUs";
import Contact from "./views/ContactUs/Contact.jsx";
import Services from "./views/Services/Services.jsx";
import Digital from "./views/Services/Digital.jsx";
import Laser from "./views/Services/Laser.jsx";
import Cosmetic from "./views/Services/Cosmetic.jsx";
import Endodontics from "./views/Services/Endodontics.jsx";
import Implants from "./views/Services/Implants.jsx";
import Invisalion from "./views/Services/Invisalion.jsx";
import Oral from "./views/Services/Oral.jsx";
import Orthodontic from "./views/Services/Orthodontic.jsx";
import Pediatric from "./views/Services/Pediatric.jsx";
import Peridontics from "./views/Services/Peridontics.jsx";
import Prosthodontics from "./views/Services/Prosthodontics.jsx";
import Restorative from "./views/Services/Restorative.jsx";
import Gallery from "./views/Gallery/Gallery";
import Loadable from "./component/Loadable.jsx";
import { lazy } from "react";
import withRightClickDisable from "./component/RightClickDisable.jsx";
// const Gallery = Loadable(lazy(() => import("./views/Gallery/Gallery")));

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/services" element={<Services />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services/digital" element={<Digital />} />
        <Route path="/services/laser" element={<Laser />} />
        <Route path="/services/cosmetic" element={<Cosmetic />} />
        <Route path="/services/endodontics" element={<Endodontics />} />
        <Route path="/services/implants" element={<Implants />} />
        <Route path="/services/invisalion" element={<Invisalion />} />
        <Route path="/services/oral" element={<Oral />} />
        <Route path="/services/orthodontics" element={<Orthodontic />} />
        <Route path="/services/pediatric" element={<Pediatric />} />
        <Route path="/services/periodontics" element={<Peridontics />} />
        <Route path="/services/prosthodontics" element={<Prosthodontics />} />
        <Route path="/services/restorative" element={<Restorative />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
