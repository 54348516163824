import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";

function Orthodontic() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        Orthodontic Treatment
      </Typography>
      <img
        src={"/assets/services/Ortho.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "50px",
            }}
          >
            <Typography fontWeight={"bold"}>
              What is Orthodontic Treatment?
            </Typography>
            <Typography>
              Orthodontic treatment is a way of straightening or moving teeth,
              to improve the appearance of the teeth and how they work. It can
              also help to look after the long-term health of your teeth, gums
              and jaw joints, by spreading the biting pressure over all your
              teeth.
            </Typography>
            <Typography fontWeight={"bold"}>
              Why Should I Have Orthodontic Treatment?
            </Typography>
            <Typography>
              Many people have crowded or crooked teeth. Orthodontic treatment
              will straighten the teeth or move them into a better position.
              This can improve their appearance and the way the teeth bite
              together, while also making them easier to clean. Some people have
              upper front teeth that stick out and look unsightly. These
              'prominent' teeth are more likely to be damaged, but orthodontic
              treatment(teeth braces) can move them back into line. Or the way
              the upper and lower jaws meet can cause teeth to look unsightly
              and lead to an incorrect bite. Orthodontic treatment may be able
              to correct both of these problems. When the teeth don't meet
              correctly, this can put strain on the muscles of the jaw, causing
              jaw & joint problem and sometimes headaches. Orthodontic treatment
              can help you to bite more evenly and reduce the strain.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(3) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>
              Treatments will use to make your Smile better.
            </Typography>
            <strong>
              <li> Braces: Metal/Tooth Colored</li>
              <li> Correction of Spaced & Irregular Teeth</li>
              <li>Myofunctional Orthopedics </li>
              <li>Corrective Jaw Surgery/ Orthognathic Surgery</li>
              <li>Correction of Protruded Teeth </li>
              <li>Smile Makeover</li>
              <li>Preventive & Interceptive Orthodontics</li>
              <li>Clear Aligners: Invisalign</li>
              <li>Clear Retainers: Essix Retainers</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Orthodontic;
