import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

function Endodontics() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontSize: "24px",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        ENDODONTICS
      </Typography>
      <img
        src={"/assets/services/endodoncia.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "10px" : "30px",
          marginTop: "20px",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "70px",
            }}
          >
            <Typography fontWeight="bold">What is Endodontics?</Typography>
            <Typography>
              Endodontics is the branch of dentistry concerning dental pulp &
              tissues surrounding the roots of a tooth. Root canal treatment is
              necessary when the centre past within the tooth, known as dental
              pulp, knowing the blood vessels, nerves &living connective
              tissues, Become infected or inflamed.
            </Typography>
            <Typography>
              The most common causes of tooth damage or infections or deep
              caries left untreated, cracked or broken tooth due to any trauma &
              gum diseases. During root canal treatment, an Endodontist who is
              specialised in such treatment carefully removes the pulp inside
              the tooth, cleans, disinfects & shapes the root canals & places a
              filling to seal the space.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>Services we provide:</Typography>
            <strong>
              <li>Root Canal Treatment(RCT)</li>
              <li>Fractured Teeth Management</li>
              <li>RE-RCT</li>
              <li>RE-Implantation</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Endodontics;
