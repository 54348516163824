import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";

function Invisalion() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        INVISALIGN
      </Typography>
      <img
        src={"/assets/services/invisalign.jpeg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "60px",
            }}
          >
            <Typography fontWeight={"bold"}>About INVISALIGN</Typography>
            <Typography>
              Invisalign, an alternative to traditional metal braces, is a
              treatment that uses clear aligners to straighten teeth, bridge
              gaps, and correct crowding and mild bite issues. It's particularly
              popular among adults whose teeth may have shifted in the decades
              since they got their braces removed or who never got braces to
              begin with.
            </Typography>
            <Typography>
              Invisalign treatment uses a series of removable clear aligners to
              straighten teeth and correct gaps, crowding, and mild bite issues.
              An alternative to traditional metal braces, these flexible plastic
              aligner trays are made from a flexible material called SmartTrack.
              The aligners (aka trays) fit snugly over your teeth to gradually
              adjust their position. They're worn 24/7, except for when eating,
              drinking, or brushing your teeth.
            </Typography>
            <Typography>
              Invisalign clear aligners are an option for children, teens, and
              adults, but the orthodontic treatment is especially popular with
              adults who had braces when they were younger and didn't wear a
              retainer to keep their teeth from shifting out of alignment.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>Invisalign works to:</Typography>
            <strong>
              <li>straighten crooked teeth</li>
              <li> correct malocclusion (crowded teeth)</li>
              <li>correct some underbites, open bites, and crossbites</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Invisalion;
