import React, { useState } from "react";
import { Pannellum } from "@readr-media/pannellum-react";
import { Grid } from "@mui/material";
import scenes from "./dataScene";

const Example = () => {
  const [currentScene, setCurrentScene] = useState(scenes["outside"]);

  const hotSpots = (element, i) => {
    return (
      <Pannellum.Hotspot
        key={i}
        type="custom"
        pitch={element.pitch}
        yaw={element.yaw}
        handleClick={() => {
          setCurrentScene(scenes[element.scene]);
        }}
      />
    );
  };

  return (
    <Pannellum
      width="100%"
      height="500px"
      autoLoad
      autoRotate='-2'
      showFullscreenCtrl={false}
      image={currentScene.image}
      title={currentScene.title}
      key={currentScene.key}
      id="2"
      sceneId={currentScene.key}
      pitch={currentScene.pitch}
      yaw={currentScene.yaw}
      hfov={currentScene.hfov}
    >
      {Object.values(currentScene.hotSpot).map((element, i) =>
        hotSpots(element, i)
      )}
    </Pannellum>
  );
};

export default Example;
