import { Grid, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useEffect } from "react";

function Prosthodontics() {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width: 760px)");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Typography
        style={{
          marginTop: "20px",
          fontWeight: "bold",
          fontSize: "24px",
          textAlign: "center",
        }}
      >
        PROSTHODONTICS
      </Typography>
      <img
        src={"/assets/services/prostho.jpg"}
        alt={"cosmetic"}
        height={isSmallScreen ? 150 : 600}
        width={isSmallScreen ? 200 : 800}
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          objectFit: "cover",
        }}
      />
      <Grid
        container
        spacing={2}
        style={{
          marginLeft: isSmallScreen ? "0" : "20px",
          marginTop: "20px",
          display: "flex",
        }}
      >
        <Grid
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              textAlign: "justify",
              marginLeft: isSmallScreen ? "0" : "50px",
            }}
          >
            <Typography fontWeight={"bold"}>What is Prosthodontics?</Typography>
            <Typography>
              Prosthodontics is a branch of dentistry dedicated to making
              replacements for missing or damaged teeth. Common prosthodontic
              treatments include dentures, dental implants, crowns and bridges.
            </Typography>
            <Typography>
              Many dental treatments are prosthodontic in nature. Your general
              dentist may offer many of these procedures. But they'll likely
              refer you to a prosthodontist if your case is complex or if they
              need to coordinate treatment.
            </Typography>
            <Typography>
              Prosthodontics can treat a wide range of issues. Your general
              dentist might refer you to a prosthodontist if you have:{" "}
            </Typography>
            <ul>
              <strong>
                <li>Missing teeth</li>
                <li>Severely damaged teeth</li>
                <li>TMJ pain or dysfunction</li>
                <li>Mouth or facial pain</li>
                <li>Cosmetic concerns</li>
                <li>Obstructive sleep apnea</li>
              </strong>
            </ul>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              marginLeft: isSmallScreen ? theme.spacing(2) : theme.spacing(10),
            }}
          >
            <Typography fontWeight={"bold"}>
              Types of Prosthodontic appliances
            </Typography>
            <Typography fontWeight={"bold"}>
              There are many types of fixed and removable prosthodontic
              appliances, including:
            </Typography>
            <strong>
              <li>Crown & Bridges</li>
              <li>Flexible Dentures</li>
              <li>Partial Dentures</li>
              <li>Complete Dentures</li>
              <li>Full Mouth Dental Implant</li>
            </strong>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Prosthodontics;
