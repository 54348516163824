import React from "react";
import Director from "./Director.jsx";
import { Container } from "@mui/material";
import Team from "./team.jsx";
import Specialists from "./Specialists.jsx";
import Auxiliary from "./Auxiliary.jsx";
import Digital from "./Digital.jsx";
function AboutUs() {
  return (
    <div>
      <Container>
        <Director />
        <Team />
        <Specialists />
        <Digital />
        <Auxiliary />
      </Container>
    </div>
  );
}

export default AboutUs;
