import { Grid, Hidden } from "@mui/material";
import React from "react";
import cdc from "./cdcL.mp4";

function Video() {
  return (
    <Grid container direction="column" style={{ marginBottom: "50px" }}>
      <Hidden smDown>
        <Grid item style={{ display: "flex", flex: 1 }}>
          <video
            src={cdc}
            autoPlay
            loop
            muted
            style={{
              width: "100%",
              height: "100vh",
              objectFit: "cover",
            }}
          />
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid item style={{ width: "100%", height: "50vh" }}>
          <video
            src={cdc}
            autoPlay
            loop
            muted
            controls
            playsInline
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default Video;
