import React from "react";

function Maps() {
  return (
    <div style={{ marginTop: "30px", padding: '20px' }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3863.6303803794826!2d75.91019677594646!3d14.448452280781886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bba251e9cc8a771%3A0x37800f2d8c5e3f30!2sClassic%20Dental%20Clinic%20%26%20Orthodontic%20Centre!5e0!3m2!1sen!2sin!4v1705903550061!5m2!1sen!2sin"
        width="100%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}

export default Maps;
